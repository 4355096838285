export default class AssetType
{
    constructor() {
      this.name = ''
      this.task = ''
      this.checklist_id = ''
      this.risk_id = ''
      this.short_code = ''
      this.checklists = []
      this.custom_fields = []
      this.zone_types = []
      this.enforce_checklist_order = false
      this.allow_barcodeless_inspections = false
      this.dynamicStatuses = []
      this.selected_custom_field = null
      this.image = null
      this.image_url = null
    }
}
